import axios from "axios";
import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

//api call to fetch users list with search
const fetchUsersList = async (
  {
    searchquery,
    pageNumber,
    alphabet,
    groupId,
    hospitalId,
    isActive,
    specialityId,
  }: {
    searchquery: string;
    pageNumber: number;
    alphabet?: string;
    groupId?: string;
    hospitalId?: string;
    isActive?: boolean;
    specialityId?: string;
  },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  // const response = await axiosInstance.get(
  //   `${API_URL}${API_ENDPOINTS.USERS.FETCH_LIST_AND_SEARCH}${searchquery}&page=${pageNumber}`,
  //   config
  // );
  const response =
    alphabet || groupId || hospitalId || isActive || specialityId
      ? await axiosInstance.get(
          `${API_URL}${API_ENDPOINTS.USERS.FETCH_LIST_AND_SEARCH}${searchquery}&alphabet=${alphabet}&groups_id=${groupId}&hospital_id=${hospitalId}&is_active=${isActive}&speciality_id=${specialityId}&page=${pageNumber}`,
          config
        )
      : await axiosInstance.get(
          `${API_URL}${API_ENDPOINTS.USERS.FETCH_LIST_AND_SEARCH}${searchquery}&page=${pageNumber}`,
          config
        );

  return response.data;
};

//api to fetch user details to display in user card modal
const fetchUserDetails = async (
  { userId, startDate, endDate }: { userId: number; startDate: any; endDate: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.USERS.FETCH_USER_DETAILS}${userId}/?start_date=${startDate}&end_date=${endDate}`,
    config
  );
  return response.data;
};

//api to delete,activate,deactivate users
const multiFunctionDeleteUsers = async (deletePayload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.USERS.DELETE_USER}`, {
    ...config,
    data: deletePayload,
  });

  return response.data;
};

//api to upload csv file for bulk user adding
const uploadFile = async (uploadFile: File, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USERS.UPLOAD_CSV_FILE}`, uploadFile, config);
  return response.data;
};

const fetchAttributeValues = async ( token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.USERS.UPLOAD_CSV_FILE}`, config);
  return response.data;
};

//api to  add user
const addUser = async (userPayload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USERS.ADD_USER}`, userPayload, config);
  return response.data;
};

//api to  edit user
// const editUser = async (userPayload: any, token: string): Promise<any> => {
//   const config = {
//     headers: {
//       Authorization: `Token ${token}`,
//     },
//   };
//   const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USERS.ADD_USER}`, userPayload, config);
//   return response.data;
// };

//api to  get user added details
const fetchUserAddedDetails = async (userId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.USERS.FETCH_ADDED_USER_DETAILS}${userId}/update/fetch/`,
    config
  );
  return response.data;
};

//api to  update user details
const updateUserDetails = async (
  { userId, userPayload }: { userId: any; userPayload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.USERS.FETCH_ADDED_USER_DETAILS}${userId}/update/`,
    userPayload,
    config
  );
  return response.data;
};

//api to fetch country list -this will be common to use everywhere in the app
const fetchCountry = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.USERS.COUNTRY_LIST}`, config);
  return response.data;
};

//api to add a new country code
const addCountryCode = async (countryCodePayload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.USERS.COUNTRY_LIST}`,
    countryCodePayload,
    config
  );
  return response.data;
};

//api to delete  country code
const deleteCountryCode = async (countryCodeID: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(
    `${API_URL}${API_ENDPOINTS.USERS.DELETE_COUNTRY_CODE}${countryCodeID}/`,
    config
  );
  return response.data;
};

//api to update the public holiday on user card
const updatePublicHoliday = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USERS.UPDATE_PUBLIC_HOLIDAY}`, payload, config);
  return response.data;
};

////api for add day off and avoid for leave days
const addDayoffAvoid = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USERS.ADD_DAYOFF_AVOID}`, payload, config);
  return response.data;
};

////api for remove day off and avoid for leave days
const removeDayoffAvoid = async (
  { userId, scheduleId, cgsValue }: { userId: number; scheduleId: any; cgsValue: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.USERS.REMOVE_DAYOFF_AVOID}${userId}/?schedule=${scheduleId}&cgs=${cgsValue}`,
    config
  );
  return response.data;
};

//api to fetch role based speciality

const fetchRoleBasedSpeciality = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.USERS.FETCH_ROLE_BASED_SPECIALITY}`, config);
  return response.data;
};

//api call to resend email to users
const resendEmail = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USERS.RESEND_EMAIL}`, payload, config);
  return response.data;
};
//---------------------------------------------
//fetch schedule list on  user leave section
const fetchScheduleList = async (
  { userId, selectedTimezone }: { userId: number; selectedTimezone: string },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.USERS.FETCH_SCHEDULE_LIST}${userId}/?timezone=${selectedTimezone}`,
    config
  );
  return response.data;
};

//to create leave for user
const createLeave = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USERS.LEAVE_CRUD}`, payload, config);
  return response.data;
};

//to fetch leave details
const fetchLeaveDetails = async (leaveId: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.USERS.LEAVE_CRUD}${leaveId}/`, config);
  return response.data;
};

//to update leave details
const updateLeaveDetails = async (
  { leaveId, payload }: { leaveId: number; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(`${API_URL}${API_ENDPOINTS.USERS.LEAVE_CRUD}${leaveId}/`, payload, config);
  return response.data;
};

//to remove leave
const removeLeave = async (leaveId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.USERS.LEAVE_CRUD}${leaveId}/`, config);
  return response.data;
};

//to validate leave
const validateLeave = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USERS.VALIDATE_LEAVE}`, payload, config);
  return response.data;
};

const usersService = {
  fetchUsersList,
  fetchUserDetails,
  multiFunctionDeleteUsers,
  uploadFile,
  addUser,
  // editUser,
  fetchUserAddedDetails,
  updateUserDetails,
  fetchCountry,
  updatePublicHoliday,
  addCountryCode,
  addDayoffAvoid,
  removeDayoffAvoid,
  fetchRoleBasedSpeciality,
  resendEmail,
  deleteCountryCode,
  fetchScheduleList,
  createLeave,
  fetchLeaveDetails,
  updateLeaveDetails,
  removeLeave,
  validateLeave,
  fetchAttributeValues
};

export default usersService;
