export const API_URL = process.env.REACT_APP_API_URL;
export const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;
export const DASHBOARD_APP_URL = process.env.REACT_APP_DASHBOARD_APP_URL;

export const API_ENDPOINTS = {
  AUTH: {
    AUTH_LOGIN: "/user/login/",
    RECOVER_LOGIN: "/user/reset-password/",
    RESET_PASSWORD: "/user/reset-password/",
    FORGOT_USERNAME: "/user/forgot-username/",
    GET_PRIVACY_POLICY: "/user/privacy/",
    GET_TERMS_CONDTIONS: "/user/terms/",
    LOGOUT: "/user/logout/",
  },

  HOME: {
    HOME_FETCH_SIDEBAR_AND_CARD_ITEMS: "/user/permissions/",
    CHANGE_PASSWORD: "/user/change-password/",
    ABOUT_US: "/user/about-us/",
  },

  SITES: {
    SITES_LIST_FETCH: "/site/",
    SITES_LIST_FETCH_BY_SEARCH: "/site/search/?name=",
    SITES_ADD_NEW: "/site/add/",
    SITES_FETCH_USER_ADDED: "/site/add-search/?id=",
    SITES_EDIT: "/site/",
    SITES_DELETE: "/user/site/",
    SITES_FETCH_STATE_LIST: "/site/state/?global=true",
    SITES_FETCH_CITY_LIST: "/site/state-city-list/?global=true&state_id=",
    SITES_TIMEZONE: "/site/timezone/",
    SITES_UPDATE_ADDED_DETAILS: "/",
  },

  SPECIALITY: {
    FETCH_LIST: "/user/speciality/search/",
    ADD_NEW: "/user/speciality/add/",
    SEARCH: "/user/speciality/search/?name=",
    UPDATE: "/user/speciality/",
    FETCH_BY_ID: "/user/speciality/",
    TOOGLE_ACTION: "/user/speciality/",
    DELETE_SPECIALITY: "/user/speciality-delete/",
  },

  ROLES_PERMISSIONS: {
    ADD_ROLE: "/user/roles/add/",
    EDIT_ROLE: "/user/roles/update/",
    FETCH_LIST: "/user/roles/",
    FETCH_ROLE_PERMISSIONS: "/user/roles/",
    ADD_ROLES_PERMISSION: "/user/permission-add/",
    CLIENT_ROLE_PERMISSION: "/user/client-role-permission/",
    DELETE_ROLE: "/user/roles/",
  },

  USERS: {
    FETCH_LIST_AND_SEARCH: "/user/fetch/?q=",
    FETCH_USER_DETAILS: "/user/user-popup-list/",
    DELETE_USER: "/user/user-page/delete/",
    UPLOAD_CSV_FILE: "/user/user-onboarding/",
    ADD_USER: "/user/user-page/add/",
    FETCH_ADDED_USER_DETAILS: "/user/user-page/",
    UPDATE_USER_DETAILS: "/user/user-page/",
    COUNTRY_LIST: "/user/country/",
    DELETE_COUNTRY_CODE: "/user/country/",
    UPDATE_PUBLIC_HOLIDAY: "/user/public-holiday-reference/add/",
    ADD_DAYOFF_AVOID: "/shedule/schedule-add/",
    REMOVE_DAYOFF_AVOID: "/shedule/schedule-remove/",
    FETCH_ROLE_BASED_SPECIALITY: "/user/role-based-speciality/",
    RESEND_EMAIL: "/user/resend-email/",
    FETCH_SCHEDULE_LIST: "/shedule/user-schedules/",
    LEAVE_CRUD: "/user/leave/",
    VALIDATE_LEAVE: "/user/validate-leave/",
  },

  GROUPS: {
    FETCH_GROUP_LIST: "/user/list-group/",
    DELETE_GROUP: "/user/delete-group/",
    GROUP_USER_LIST: "/user/group-user-list/",
    CREATE_GROUP: "/user/add-group/",
    FETCH_USERS_BY_GROUP: "/user/assign-users/fetch/",
    UPDATE_GROUP_USERS: "/user/assign-users/add/",
    UNASSIGNED_SHIFT_LIST: "/shedule/unassigned-shift-list/",
    UNASSIGNED_SHIFT_USER_LIST: "/shedule/unassigned-shift-users/",
    UNASSIGNED_SHIFT_RC: "/shedule/unassigned-shift-rc/",
    FETCH_GROUP_DETAILS: "/user/list-group/",
    UPDATE_GROUP_DETAILS: "/user/update-group/",
  },

  SCHEDULE: {
    FETCH_SCHEDULES_BY_GROUP: "/user/group-schedule/",
    FETCH_SCHEDULE_DETAILS: "/user/group-schedule/",
    CREATE_SCHEDULE: "/user/group-schedule-name/add/",
    REMOVE_SCHEDULE: "",
    UPDATE_SCHEDULE: "/user/group-schedule/",
    ADD_SITES_TO_SCHEDULE: "/user/group-schedule-site/",
    ADD_USER_TO_SCHEDULE: "/user/group-schedule-users/",
    DELETE_SCHEDULE: "/user/group-schedule/",
    REMOVE_SITE_FROM_SCHEDULE: "/user/group-schedule-site/",
    REMOVE_USER_FROM_SCHEDULE: "/user/group-schedule-user/",
    SCHEDULE_NAME_CHANGE: "/user/group-schedule/",
  },

  SHIFT: {
    ADD_SHIFT: "/user/group-schedule/shift/add/",
    FETCH_SHIFT_LIST: "/user/group-schedule/shift/add/",
    FETCH_SHIFT_DETAILS: "/user/group-schedule/shift/add/",
    UPDATE_SHIFT_DETAILS: "/user/group-schedule/shift/add/",
    DELETE_SHIFT: "/user/group-schedule/shift/add/",
    FETCH_SCHEDULE_USERS: "/user/group-schedule/",
    FETCH_GROUP_SCHEDULER: "/user/group-scheduler-fetch/",
    SCHEDULE_SHIFT_RULE_CHECK: "/user/group-shift-useradd/rulecheck/",
    SCHEDULE_ASSIGN_SHIFT: "/user/group-shift-useradd/shiftadd/",
    SCHEDULE_SHIFT_LIST: "/user/group-schedule/shift/list/",
  },
  USER_ROLE: {
    CREATE_USER_ROLE: "/user/user-rule/",
    FETCH_USER_ROLE: "/user/user-rule/",
    CLEAR_RULE: "/automate/user-rule-clear/",
    FETCH_AVAILABLE_USER_LIST: "/automate/available_users/",
  },

  SCHEDULE_RULE: {
    CREATE_SCHEDULE_RULE: "/automate/shift-rule-v2/",
    UPDATE_SCHEDULE_RULE: "/automate/shift-rule-v2/",
    FETCH_SCHEDULE_RULE_DETAILS: "/automate/shift-rule-v2/",
    FETCH_SCHEDULE_RULE_LIST: "/automate/shift-rule-v2/",
    APPLY_SCHEDULE_RULE: "/automate/apply_shift_assignment_rule/",
    UPDATE_SCHEDULE_CALENDER_SHIFT: "/user/group-schedule-user/",
  },

  UNIT_DEPARTMENT: {
    FETCH_SITE_LIST: "/unit/list-sites/",
    FETCH_UNIT_DEPARTMENT_LIST: "/unit/list-unit/",
    ADD_UNIT_DEPARTMENT: "/unit/list-unit/",
    UPDATE_UNIT_DEPARTMENT: "/unit/list-unit/",
    DELETE_UNIT_DEPARTMENT: "/unit/list-unit/",
    FETCH_UNIT_DEPARTMENT_USERS: "/unit/list-unit-admin/",

    FETCH_BED_LIST: "/unit/bed/",
    DELETE_BED: "/unit/bed/",
    ADD_BED: "/unit/bed/",
    FETCH_BED_DETAILS: "/unit/bed/",
    UPDATE_BED_DETAILS: "/unit/bed/",

    FETCH_NURSE_LIST: "/unit/assign-nurse/",
    ASSIGN_NURSE: "/unit/assign-nurse/",
    FETCH_ASSIGNED_NURSE: "/unit/assign-nurse/",
    UNASSIGN_NURSE: "/unit/assign-nurse/",
  },

  STATUS_BOARD: {
    FETCH_STATUS_BOARD_LIST: "/unit/status-board/",
    ADD_STATUS_BOARD: "/unit/status-board/",
    FETCH_STATUS_BOARD_DETAILS: "/unit/status-board/",
    UPDATE_STATUS_BOARD: "/unit/status-board/",
    FETCH_UNIT_LIST: "/unit/fetch/",
    DELETE_STATUS_BOARD: "/unit/sb/delete/",
  },

  STATUS_BOARD_COLUMN: {
    FETCH_STATUS_BOARD_COLUMN_LIST: "/unit/status-board-column/",
    DELETE_STATUS_BOARD_COLUMN: "/unit/status-board-column/",
    CREATE_STATUS_BOARD_COLUMN: "/unit/status-board-column/",
    UPDATE_STATUS_BOARD_COLUMN: "/unit/status-board-column/",
    FETCH_STATUS_BOARD_COLUMN_DETAILS: "/unit/status-board-column/",
    FETCH_VALUE: "/unit/status-board-colum-type/",
  },

  SUPPORT_STAFF: {
    FETCH_SUPPORT_STAFF_LIST: "/unit/support-staff/",
    DELETE_SUPPORT_STAFF: "/unit/support-staff-delete/",
    ADD_SUPPORT_STAFF: "/unit/support-staff/",
    UPDATE_SUPPORT_STAFF: "/unit/support-staff/",
    FETCH_SUPPORT_STAFF_DETAILS: "/unit/support-staff/",
  },

  CARE_TEAM: {
    FETCH_CARE_TEAM_LIST: "/unit/care-team/",
    DELETE_CARE_TEAM: "/unit/care-team-delete/",
    FETCH_SCHEDULES: "/unit/schedule-list/",
    ADD_CARE_TEAM: "/unit/care-team/",
    UPDATE_CARE_TEAM: "/unit/care-team/",
    FETCH_CARE_TEAM_DETAILS: "/unit/care-team/",
  },

  EMS_REPORT_MANAGEMENT: {
    FETCH_EMS_LIST: "/report/ems-list/",
    FETCH_CASE_TYPE_LIST: "/report/case-type-list/",
    FETCH_EMERGENCY_USER_LIST: "/report/emergency-user-list/",
    FETCH_EMT_USERS_LIST: "/report/emt-users-list/",
    GENERATE_EMS_REPORT: "/report/generate-ems-report/",
    GENERATE_CSV_REPORT: "/report/generate-ems-csv-report/",
    DOWNLOAD_CSV: "/report/generate-ems-csv/",
  },

  SCHEDULE_REPORT: {
    FETCH_SCHEULE_LIST: "/unit/schedule-list/",
    FETCH_USER_SHIFT_SCHEDULE_REPORT: "/report/user-shift-schedule/",
    FETCH_SCHEDULE_HISTORY_REPORT: "/report/schedule-history/",
    FETCH_ON_CALL_LOOKUP_HISTORY_REPORT: "/report/oncall-lookup-history/",
    FETCH_ON_CALL_LOOKUP_HISTORY_GROUP_LIST: "/report/group-list/",
  },

  BED_REPORT: {
    FETCH_DOCTOR_LIST: "/report/get-doctor-list/",
    FETCH_CARE_TEAM_LIST: "/report/get-called-care-team-list/",
    FETCH_ASSIGNED_NURSE_LIST: "/report/get-assigned-nurse-list/",
    FETCH_CALLED_NURSE_LIST: "/report/get-called-nurse-list/",
    GENERATE_PREVIEW: "/report/bed-report-preview/",
    DOWNLOAD_CSV: "/report/generate-bed-csv/",
  },

  DASHBOARD: {
    FETCH_SITE_LIST: "/api/v1/site-list",
    FETCH_CARD_GROUP_LIST: "/api/v1/card-count",
    FETCH_USER_PROFILE: "/api/v1/user-profile",
    FETCH_USER_LIST: "/dashboard/user-list/",
    FETCH_ROLE_LIST: "/api/v1/auth-group",
    FETCH_PROVIDER_LIST: "/dashboard/provider-list/",
    SEND_PROVIDER_MESSAGE: "/dashboard/send-message/",
    FETCH_TIMEZONE: "/api/v1/timezones",
    FETCH_GROUP_LIST: "/report/group-list/",
    FETCH_SYSTEM_USAGE_CHART: "/dashboard/system-usage-stats-chart/",
    FETCH_STATUS_BOARD_CHART: "/api/v1/units-chart",
    FETCH_BOR: "/api/v1/bed-occupancy",
    FETCH_PATIENT_CHART: "/api/v1/patients-chart",
    FETCH_EMERGENCY_CHART: "/api/v1/emergency-case-chart",
    FETCH_SITES_BY_PATIENT_CHART: "/api/v1/site-patients-chart",
  },
  ASSIGN_NURSE: {
    FETCH_ASSIGN_NURSE_TABLE_DATA: "/unit/assign-nurse/",
  },
  HELP:{
    FETCH_HELP_VIDEO_DATA:"/additional-cards/help/",
    ADD_HELP_VIDEO:"/additional-cards/help/",
    EDIT_HELP_VIDEO:"/additional-cards/help/",
    DELETE_HELP_VIDEO:"/additional-cards/help/",
    CHANGE_HELP_VIDEO_ORDER:"/additional-cards/help-ordering/",
    RETRIEVE_HELP_VIDEO:"/additional-cards/help/"
  },
  PUBLIC_HOLIDAYS: {
    FETCH_PUBLIC_HOLIDAYS_LIST: "/additional-cards/public-holiday/",
    ADD_PUBLIC_HOLIDAY: "/additional-cards/public-holiday/",
    UPDATE_PUBLIC_HOLIDAY: "/additional-cards/public-holiday/",
    DELETE_PUBLIC_HOLIDAY: "/additional-cards/public-holiday/",
    FETCH_PUBLIC_HOLIDAY_DETAILS: "/additional-cards/public-holiday/",
  },
};
