import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import usersService from "./usersService";

interface UsersSlice {
  fetchUsersList: {
    fetchUsersListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
    totalCount: number;
  };
  fetchAttributeValues: {
    fetchAttributeValuesData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
    totalCount: number;
  };
  fetchUserDetails: {
    fetchUserDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  multiFunctionDeleteUsers: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  uploadFile: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  addUser: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  editUser: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchUserAddedDetails: {
    userAddedData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateUserDetails: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchCountry: {
    countryList: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updatePublicHoliday: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  addDayoffAvoid: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  removeDayoffAvoid: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchRoleBasedSpeciality: {
    roleBasedSpecialityData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleList: {
    scheduleListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  createLeave: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchLeaveDetails: {
    leaveDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateLeaveDetails: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  removeLeave: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  validateLeave: {
    validateLeaveData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: UsersSlice = {
  fetchUsersList: {
    fetchUsersListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    totalCount: 0,
  },
  fetchAttributeValues: {
    fetchAttributeValuesData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    totalCount: 0,
  },
  fetchUserDetails: {
    fetchUserDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  multiFunctionDeleteUsers: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  uploadFile: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  addUser: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  editUser: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUserAddedDetails: {
    userAddedData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateUserDetails: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchCountry: {
    countryList: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updatePublicHoliday: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  addDayoffAvoid: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  removeDayoffAvoid: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchRoleBasedSpeciality: {
    roleBasedSpecialityData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleList: {
    scheduleListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  createLeave: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchLeaveDetails: {
    leaveDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateLeaveDetails: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  removeLeave: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  validateLeave: {
    validateLeaveData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

//action to fetch  user list and searching user
export const fetchUsersList = createAsyncThunk(
  "users/fetchUsersList",
  async (
    {
      searchquery,
      pageNumber,
      alphabet,
      groupId,
      hospitalId,
      isActive,
      specialityId,
    }: {
      searchquery: string;
      pageNumber: number;
      alphabet?: string;
      groupId?: string;
      hospitalId?: string;
      isActive?: boolean;
      specialityId?: string;
    },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await usersService.fetchUsersList(
        { searchquery, pageNumber, alphabet, groupId, hospitalId, isActive, specialityId },
        token
      );
      if (response.isSuccess) {
        const formattedData = {
          total_count: response?.result?.total_count,
          result: response?.result?.user_data,
        };
        return { ...response, result: formattedData };
        // return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch user details to display in user card modal
export const fetchUserDetails = createAsyncThunk(
  "users/fetchUserDetails",
  async ({ userId, startDate, endDate }: { userId: number; startDate: any; endDate: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await usersService.fetchUserDetails({ userId, startDate, endDate }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to delete,activate,deactivate users
export const multiFunctionDeleteUsers = createAsyncThunk(
  "users/multiFunctionDeleteUsers",
  async (deletePayload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await usersService.multiFunctionDeleteUsers(deletePayload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to upload csv file for bulk user adding
export const uploadFile = createAsyncThunk("users/uploadFile", async (file: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.uploadFile(file, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchAttributeValues = createAsyncThunk("users/fetchAttributeValues", async ({}:any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.fetchAttributeValues(token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Action to add user
export const addUser = createAsyncThunk("users/addUser", async (userPayload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.addUser(userPayload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch user added details
export const fetchUserAddedDetails = createAsyncThunk("users/fetchUserAddedDetails", async (userId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.fetchUserAddedDetails(userId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to update user details
export const updateUserDetails = createAsyncThunk(
  "users/updateUserDetails",
  async ({ userId, userPayload }: { userId: any; userPayload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await usersService.updateUserDetails({ userId, userPayload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch country details
export const fetchCountry = createAsyncThunk("fetchCountry", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.fetchCountry(token);
    if (response) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to add country details
export const addCountryCode = createAsyncThunk("addCountryCode", async (countryPayload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.addCountryCode(countryPayload, token);
    if (response) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to delete country code
export const deleteCountryCode = createAsyncThunk(
  "users/deleteCountryCode",
  async (countryCodeID: string, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await usersService.deleteCountryCode(countryCodeID, token);
      if (response) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePublicHoliday = createAsyncThunk("users/updatePublicHoliday", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.updatePublicHoliday(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//add day off and avoid for leave days
export const addDayoffAvoid = createAsyncThunk("users/addDayoffAvoid", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.addDayoffAvoid(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//add day remove and avoid for leave days
export const removeDayoffAvoid = createAsyncThunk(
  "users/removeDayoffAvoid",
  async ({ userId, scheduleId, cgsValue }: { userId: number; scheduleId: any; cgsValue: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await usersService.removeDayoffAvoid({ userId, scheduleId, cgsValue }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action for fetching role based speciality

export const fetchRoleBasedSpeciality = createAsyncThunk("users/fetchRoleBasedSpeciality", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.fetchRoleBasedSpeciality(token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to resend email to users
export const resendEmail = createAsyncThunk("users/resendEmail", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.resendEmail(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch schedule list on  user leave section
export const fetchScheduleList = createAsyncThunk(
  "users/fetchScheduleList",
  async ({ userId, selectedTimezone }: { userId: number; selectedTimezone: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await usersService.fetchScheduleList({ userId, selectedTimezone }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to  create leave for user
export const createLeave = createAsyncThunk("users/createLeave", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.createLeave(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch leave details
export const fetchLeaveDetails = createAsyncThunk("users/fetchLeaveDetails", async (leaveId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.fetchLeaveDetails(leaveId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to update leave details
export const updateLeaveDetails = createAsyncThunk(
  "users/updateLeaveDetails",
  async ({ leaveId, payload }: { leaveId: number; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await usersService.updateLeaveDetails({ leaveId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to remove leave
export const removeLeave = createAsyncThunk("users/removeLeave", async (leaveId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.removeLeave(leaveId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to remove leave
export const validateLeave = createAsyncThunk("users/validateLeave", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await usersService.validateLeave(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      return thunkAPI.rejectWithValue({ message: response.message, result: response.result });
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//users slice
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetFetchUsersList: (state) => {
      state.fetchUsersList = initialState.fetchUsersList;
    },
    resetFetchUserDetails: (state) => {
      state.fetchUserDetails = initialState.fetchUserDetails;
    },
    resetMultiFunctionDeleteUsers: (state) => {
      state.multiFunctionDeleteUsers = initialState.multiFunctionDeleteUsers;
    },
    resetuploadFile: (state) => {
      state.uploadFile = initialState.uploadFile;
    },
    resetFetchAttributeValues: (state) => {
      state.fetchAttributeValues = initialState.fetchAttributeValues;
    },
    resetAddUser: (state) => {
      state.addUser = initialState.addUser;
    },
    resetEditUser: (state) => {
      state.editUser = initialState.editUser;
    },
    resetFetchUserAddedDetails: (state) => {
      state.fetchUserAddedDetails = initialState.fetchUserAddedDetails;
    },
    resetUpdateUserDetails: (state) => {
      state.updateUserDetails = initialState.updateUserDetails;
    },
    resetFetchCountry: (state) => {
      state.fetchCountry = initialState.fetchCountry;
    },
    resetUpdatePublicHoliday: (state) => {
      state.updatePublicHoliday = initialState.updatePublicHoliday;
    },
    resetAddDayoffAvoid: (state) => {
      state.addDayoffAvoid = initialState.addDayoffAvoid;
    },
    resetRemoveDayoffAvoid: (state) => {
      state.removeDayoffAvoid = initialState.removeDayoffAvoid;
    },
    resetFetchRoleBasedSpeciality: (state) => {
      state.fetchRoleBasedSpeciality = initialState.fetchRoleBasedSpeciality;
    },
    resetFetchScheduleList: (state) => {
      state.fetchScheduleList = initialState.fetchScheduleList;
    },
    resetCreateLeave: (state) => {
      state.createLeave = initialState.createLeave;
    },
    resetFetchLeaveDetails: (state) => {
      state.fetchLeaveDetails = initialState.fetchLeaveDetails;
    },
    resetUpdateLeaveDetails: (state) => {
      state.updateLeaveDetails = initialState.updateLeaveDetails;
    },
    resetRemoveLeave: (state) => {
      state.removeLeave = initialState.removeLeave;
    },
    resetValidateLeave: (state) => {
      state.validateLeave = initialState.validateLeave;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersList.pending, (state, action: PayloadAction<any>) => {
        state.fetchUsersList.isLoading = true;
      })
      .addCase(fetchUsersList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUsersList.isLoading = false;
        state.fetchUsersList.isSuccess = true;
        state.fetchUsersList.totalCount = action.payload.result.total_count;
        state.fetchUsersList.fetchUsersListData = action.payload.result.result;
      })
      .addCase(fetchUsersList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUsersList.isLoading = false;
        state.fetchUsersList.isError = true;
        state.fetchUsersList.message = action.payload;
      })
      .addCase(fetchUserDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchUserDetails.isLoading = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUserDetails.isLoading = false;
        state.fetchUserDetails.isSuccess = true;
        state.fetchUserDetails.fetchUserDetailsData = action.payload.result;
      })
      .addCase(fetchUserDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUserDetails.isLoading = false;
        state.fetchUserDetails.isError = true;
        state.fetchUserDetails.message = action.payload;
      })
      .addCase(fetchAttributeValues.pending, (state, action: PayloadAction<any>) => {
        state.fetchAttributeValues.isLoading = true;
      })
      .addCase(fetchAttributeValues.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchAttributeValues.isLoading = false;
        state.fetchAttributeValues.isSuccess = true;
        state.fetchAttributeValues.fetchAttributeValuesData = action.payload.result;
      })
      .addCase(fetchAttributeValues.rejected, (state, action: PayloadAction<any>) => {
        state.fetchAttributeValues.isLoading = false;
        state.fetchAttributeValues.isError = true;
        state.fetchAttributeValues.message = action.payload;
      })
      .addCase(multiFunctionDeleteUsers.pending, (state, action: PayloadAction<any>) => {
        state.multiFunctionDeleteUsers.isLoading = true;
      })
      .addCase(multiFunctionDeleteUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.multiFunctionDeleteUsers.isLoading = false;
        state.multiFunctionDeleteUsers.isSuccess = true;
      })
      .addCase(multiFunctionDeleteUsers.rejected, (state, action: PayloadAction<any>) => {
        state.multiFunctionDeleteUsers.isLoading = false;
        state.multiFunctionDeleteUsers.isError = true;
        state.multiFunctionDeleteUsers.message = action.payload;
      })
      .addCase(uploadFile.pending, (state, action: PayloadAction<any>) => {
        state.uploadFile.isLoading = true;
      })
      .addCase(uploadFile.fulfilled, (state, action: PayloadAction<any>) => {
        state.uploadFile.isLoading = false;
        state.uploadFile.isSuccess = true;
      })
      .addCase(uploadFile.rejected, (state, action: PayloadAction<any>) => {
        state.uploadFile.isLoading = false;
        state.uploadFile.isError = true;
        state.uploadFile.message = action.payload;
      })
      .addCase(addUser.pending, (state, action: PayloadAction<any>) => {
        state.addUser.isLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.addUser.isLoading = false;
        state.addUser.isSuccess = true;
      })
      .addCase(addUser.rejected, (state, action: PayloadAction<any>) => {
        state.addUser.isLoading = false;
        state.addUser.isError = true;
        state.addUser.message = action.payload;
      })
      // .addCase(editUser.pending, (state, action: PayloadAction<any>) => {
      //   state.editUser.isLoading = true;
      // })
      // .addCase(editUser.fulfilled, (state, action: PayloadAction<any>) => {
      //   state.editUser.isLoading = false;
      //   state.editUser.isSuccess = true;
      // })
      // .addCase(editUser.rejected, (state, action: PayloadAction<any>) => {
      //   state.editUser.isLoading = false;
      //   state.editUser.isError = true;
      //   state.editUser.message = action.payload;
      // })
      .addCase(fetchUserAddedDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchUserAddedDetails.isLoading = true;
      })
      .addCase(fetchUserAddedDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUserAddedDetails.isLoading = false;
        state.fetchUserAddedDetails.isSuccess = true;
        state.fetchUserAddedDetails.userAddedData = action.payload.result;
      })
      .addCase(fetchUserAddedDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUserAddedDetails.isLoading = false;
        state.fetchUserAddedDetails.isError = true;
        state.fetchUserAddedDetails.message = action.payload;
      })
      .addCase(updateUserDetails.pending, (state, action: PayloadAction<any>) => {
        state.updateUserDetails.isLoading = true;
      })
      .addCase(updateUserDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateUserDetails.isLoading = false;
        state.updateUserDetails.isSuccess = true;
      })
      .addCase(updateUserDetails.rejected, (state, action: PayloadAction<any>) => {
        state.updateUserDetails.isLoading = false;
        state.updateUserDetails.isError = true;
        state.updateUserDetails.message = action.payload;
      })
      .addCase(fetchCountry.pending, (state, action: PayloadAction<any>) => {
        state.fetchCountry.isLoading = true;
      })
      .addCase(fetchCountry.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchCountry.isLoading = false;
        state.fetchCountry.isSuccess = true;
        state.fetchCountry.countryList = action.payload.result;
      })
      .addCase(fetchCountry.rejected, (state, action: PayloadAction<any>) => {
        state.fetchCountry.isLoading = false;
        state.fetchCountry.isError = true;
        state.fetchCountry.message = action.payload;
      })
      .addCase(updatePublicHoliday.pending, (state, action: PayloadAction<any>) => {
        state.updatePublicHoliday.isLoading = true;
      })
      .addCase(updatePublicHoliday.fulfilled, (state, action: PayloadAction<any>) => {
        state.updatePublicHoliday.isLoading = false;
        state.updatePublicHoliday.isSuccess = true;
      })
      .addCase(updatePublicHoliday.rejected, (state, action: PayloadAction<any>) => {
        state.updatePublicHoliday.isLoading = false;
        state.updatePublicHoliday.isError = true;
        state.updatePublicHoliday.message = action.payload;
      })
      .addCase(addDayoffAvoid.pending, (state, action: PayloadAction<any>) => {
        state.addDayoffAvoid.isLoading = true;
      })
      .addCase(addDayoffAvoid.fulfilled, (state, action: PayloadAction<any>) => {
        state.addDayoffAvoid.isLoading = false;
        state.addDayoffAvoid.isSuccess = true;
      })
      .addCase(addDayoffAvoid.rejected, (state, action: PayloadAction<any>) => {
        state.addDayoffAvoid.isLoading = false;
        state.addDayoffAvoid.isError = true;
        state.addDayoffAvoid.message = action.payload;
      })
      .addCase(removeDayoffAvoid.pending, (state, action: PayloadAction<any>) => {
        state.removeDayoffAvoid.isLoading = true;
      })
      .addCase(removeDayoffAvoid.fulfilled, (state, action: PayloadAction<any>) => {
        state.removeDayoffAvoid.isLoading = false;
        state.removeDayoffAvoid.isSuccess = true;
      })
      .addCase(removeDayoffAvoid.rejected, (state, action: PayloadAction<any>) => {
        state.removeDayoffAvoid.isLoading = false;
        state.removeDayoffAvoid.isError = true;
        state.removeDayoffAvoid.message = action.payload;
      })

      .addCase(fetchRoleBasedSpeciality.pending, (state, action: PayloadAction<any>) => {
        state.fetchRoleBasedSpeciality.isLoading = true;
      })
      .addCase(fetchRoleBasedSpeciality.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchRoleBasedSpeciality.isLoading = false;
        state.fetchRoleBasedSpeciality.isSuccess = true;
        state.fetchRoleBasedSpeciality.roleBasedSpecialityData = action.payload.result;
      })
      .addCase(fetchRoleBasedSpeciality.rejected, (state, action: PayloadAction<any>) => {
        state.fetchRoleBasedSpeciality.isLoading = false;
        state.fetchRoleBasedSpeciality.isError = true;
        state.fetchRoleBasedSpeciality.message = action.payload;
      })

      .addCase(fetchScheduleList.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = true;
      })
      .addCase(fetchScheduleList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = false;
        state.fetchScheduleList.isSuccess = true;
        state.fetchScheduleList.message = action.payload.message;
        state.fetchScheduleList.scheduleListData = action.payload.result;
      })
      .addCase(fetchScheduleList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = false;
        state.fetchScheduleList.isError = true;
        state.fetchScheduleList.message = action.payload;
      })

      .addCase(createLeave.pending, (state, action: PayloadAction<any>) => {
        state.createLeave.isLoading = true;
      })
      .addCase(createLeave.fulfilled, (state, action: PayloadAction<any>) => {
        state.createLeave.isLoading = false;
        state.createLeave.isSuccess = true;
        state.createLeave.message = action.payload.message;
      })
      .addCase(createLeave.rejected, (state, action: PayloadAction<any>) => {
        state.createLeave.isLoading = false;
        state.createLeave.isError = true;
        state.createLeave.message = action.payload;
      })

      .addCase(fetchLeaveDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchLeaveDetails.isLoading = true;
      })
      .addCase(fetchLeaveDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchLeaveDetails.isLoading = false;
        state.fetchLeaveDetails.isSuccess = true;
        state.fetchLeaveDetails.message = action.payload.message;
        state.fetchLeaveDetails.leaveDetailsData = action.payload.result;
      })
      .addCase(fetchLeaveDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchLeaveDetails.isLoading = false;
        state.fetchLeaveDetails.isError = true;
        state.fetchLeaveDetails.message = action.payload;
      })

      .addCase(updateLeaveDetails.pending, (state, action: PayloadAction<any>) => {
        state.updateLeaveDetails.isLoading = true;
      })
      .addCase(updateLeaveDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateLeaveDetails.isLoading = false;
        state.updateLeaveDetails.isSuccess = true;
        state.updateLeaveDetails.message = action.payload.message;
      })
      .addCase(updateLeaveDetails.rejected, (state, action: PayloadAction<any>) => {
        state.updateLeaveDetails.isLoading = false;
        state.updateLeaveDetails.isError = true;
        state.updateLeaveDetails.message = action.payload;
      })

      .addCase(removeLeave.pending, (state, action: PayloadAction<any>) => {
        state.removeLeave.isLoading = true;
      })
      .addCase(removeLeave.fulfilled, (state, action: PayloadAction<any>) => {
        state.removeLeave.isLoading = false;
        state.removeLeave.isSuccess = true;
        state.removeLeave.message = action.payload.message;
      })
      .addCase(removeLeave.rejected, (state, action: PayloadAction<any>) => {
        state.removeLeave.isLoading = false;
        state.removeLeave.isError = true;
        state.removeLeave.message = action.payload;
      })

      .addCase(validateLeave.pending, (state, action: PayloadAction<any>) => {
        state.validateLeave.isLoading = true;
      })
      .addCase(validateLeave.fulfilled, (state, action: PayloadAction<any>) => {
        state.validateLeave.isLoading = false;
        state.validateLeave.isSuccess = true;
        state.validateLeave.message = action.payload.message;
      })
      .addCase(validateLeave.rejected, (state, action: PayloadAction<any>) => {
        state.validateLeave.isLoading = false;
        state.validateLeave.isError = true;
        state.validateLeave.message = action.payload;
        state.validateLeave.validateLeaveData = action.payload.result;
      });
  },
});

export const {
  resetFetchUsersList,
  resetFetchUserDetails,
  resetMultiFunctionDeleteUsers,
  resetAddUser,
  resetEditUser,
  resetFetchUserAddedDetails,
  resetUpdateUserDetails,
  resetFetchCountry,
  resetUpdatePublicHoliday,
  resetAddDayoffAvoid,
  resetRemoveDayoffAvoid,
  resetFetchRoleBasedSpeciality,
  resetFetchScheduleList,
  resetCreateLeave,
  resetFetchLeaveDetails,
  resetUpdateLeaveDetails,
  resetRemoveLeave,
  resetValidateLeave,
  resetFetchAttributeValues
} = usersSlice.actions;

export default usersSlice.reducer;
